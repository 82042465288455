type CompressOption = {
  file: File // 压缩后的文件
  origin?: File // 源文件
  beforeSrc?: string
  afterSrc?: string
  beforeKB?: number
  afterKB?: number
}

/**
 * 如果源文件大小 < 300KB, 返回源文件；
 * 如果源文件大小 > 300KB，压缩至300kb；
 */

export class CompressImage {
  files: File[]
  compressCount: number
  quality: number
  fileSize: number

  constructor(data: { files: File[]; quality?: number }) {
    this.files = data.files || []
    this.quality = data?.quality ?? CompressImage.getQuality(this.files)
    this.compressCount = 0
    this.fileSize = 300
  }

  static getQuality(files?: File[]): number {
    let newQuality = 0.52
    const file = files && files[0]
    const size = file?.size || 0
    if (parseInt((size / 1024).toFixed(2)) < 1024) {
      newQuality = 0.85
    }
    if (5 * 1024 < parseInt((size / 1024).toFixed(2))) {
      newQuality = 0.92
    }
    return newQuality
  }

  compressImg(): Promise<CompressOption> {
    const compressFun = (file: File, quality: number): Promise<CompressOption> => {
      return new Promise((resolve) => {
        if (Number((file.size / 1024).toFixed(2)) < this.fileSize) {
          this.compressCount = 0
          resolve({
            file: file,
          })
        } else {
          const reader = new FileReader() // 创建 FileReader
          reader.onload = (event: ProgressEvent<FileReader>) => {
            const image = new Image() // 创建 img 元素
            image.onload = async () => {
              const canvas = document.createElement('canvas') // 创建 canvas 元素
              const context = canvas.getContext('2d')
              let targetWidth = image.width
              let targetHeight = image.height
              const originWidth = image.width
              const originHeight = image.height
              if (
                1 * 1024 <= parseInt((file.size / 1024).toFixed(2)) &&
                parseInt((file.size / 1024).toFixed(2)) <= 10 * 1024
              ) {
                const maxWidth = 1600
                const maxHeight = 1600
                targetWidth = originWidth
                targetHeight = originHeight
                // 图片尺寸超过的限制
                if (originWidth > maxWidth || originHeight > maxHeight) {
                  if (originWidth / originHeight > maxWidth / maxHeight) {
                    // 更宽，按照宽度限定尺寸
                    targetWidth = maxWidth
                    targetHeight = Math.round(maxWidth * (originHeight / originWidth))
                  } else {
                    targetHeight = maxHeight
                    targetWidth = Math.round(maxHeight * (originWidth / originHeight))
                  }
                }
              }
              if (
                10 * 1024 <= parseInt((file.size / 1024).toFixed(2)) &&
                parseInt((file.size / 1024).toFixed(2)) <= 20 * 1024
              ) {
                const maxWidth = 1400
                const maxHeight = 1400
                targetWidth = originWidth
                targetHeight = originHeight
                // 图片尺寸超过的限制
                if (originWidth > maxWidth || originHeight > maxHeight) {
                  if (originWidth / originHeight > maxWidth / maxHeight) {
                    // 更宽，按照宽度限定尺寸
                    targetWidth = maxWidth
                    targetHeight = Math.round(maxWidth * (originHeight / originWidth))
                  } else {
                    targetHeight = maxHeight
                    targetWidth = Math.round(maxHeight * (originWidth / originHeight))
                  }
                }
              }
              canvas.width = targetWidth
              canvas.height = targetHeight
              if (context) {
                context.clearRect(0, 0, targetWidth, targetHeight)
                context.drawImage(image, 0, 0, targetWidth, targetHeight) // 绘制 canvas
              }
              const canvasURL = canvas.toDataURL('image/jpeg', quality)
              const buffer = atob(canvasURL.split(',')[1])
              let length = buffer.length
              const bufferArray = new Uint8Array(new ArrayBuffer(length))
              while (length--) {
                bufferArray[length] = buffer.charCodeAt(length)
              }
              const miniFile = new File([bufferArray], file.name, {
                type: 'image/jpeg',
              })
              this.compressCount++
              if (miniFile.size / 1024 > this.fileSize && this.compressCount <= 2) {
                resolve(compressFun(miniFile, Math.floor((1 / this.compressCount) * 100) / 100))
              } else {
                this.compressCount = 0
                resolve({
                  file: miniFile,
                  origin: file,
                  beforeSrc: '',
                  // beforeSrc: event?.target?.result?.src ?? '',
                  afterSrc: canvasURL,
                  beforeKB: Number(file.size),
                  afterKB: Number(miniFile.size),
                })
              }
            }
            image.src = event.target?.result as string
          }
          reader.readAsDataURL(file)
        }
      })
    }
    return compressFun(this.files[0], this.quality)
    // return Promise.all(Array.from(this.files).map((e) => compressFun(e, this.quality))) // 如果是 file 数组返回 Promise 数组
  }
}
